import { HomepageFeaturedCollectionsData } from '../../../types/hooks/getHomepageFeaturedCollections';
import { QueryParams } from '../../../types/api/BasicTypes';
import { GenericResponse } from '../../../types/api/Http';
import { CollectionResponse } from '../../../types/api/CollectionsIndexTypes';
import { AxiosError } from 'axios';
import axios from 'axios';
import cache from '../../../helpers/redis';

import { ENV } from '../../../constants/environments';

export default async function getFeaturedCollections(): Promise<HomepageFeaturedCollectionsData | AxiosError> {
  const baseUrl: string = ENV.api.baseURL;

  const collections2dUrl: string = '/collections?library_id=1';
  const collections3dUrl: string = '/collections?library_id=3';
  const collectionsPflUrl: string = '/scenes';

  const collectionsParams: QueryParams = {
    order_by: 'position',
    desc: false,
    featured: true
  };
    function fetchFeaturedCollection(collectionUrl: string) {
      return axios.get(`${baseUrl}${collectionUrl}`, { params: collectionsParams })
    }

  const get2dCollectionsPromise = fetchFeaturedCollection(collections2dUrl);
  const get3dCollectionsPromise = fetchFeaturedCollection(collections3dUrl);
  const getPflCollectionsPromise = fetchFeaturedCollection(collectionsPflUrl);


  return Promise.all([get2dCollectionsPromise, get3dCollectionsPromise, getPflCollectionsPromise])
  .then(function(values: Array<GenericResponse<{ data: Array<CollectionResponse> }>>) {
    const collection2dResponse = values[0];
    const collection3dResponse = values[1];
    const collectionPflResponse = values[2];
    return {
      library2d: collection2dResponse?.data?.data.slice(0, 20),
      library3d: collection3dResponse?.data?.data.slice(0, 20),
      libraryPfl: collectionPflResponse?.data?.data.slice(0, 20),
    };
  })
  .catch(function(error: AxiosError) {
    return error;
  });
}
