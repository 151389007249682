import React from 'react';
import PropTypes from 'prop-types';

const YoutubeEmbed = ({ embedId, className }) => {

  return (
    <div className={`video-responsive rounded-[8px] overflow-hidden ${className}`}>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}?modestbranding=1&showinfo=0&autohide=1&rel=0&loop=1&playlist=${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  className: PropTypes.string,
  autoplay: PropTypes.bool
};

export default YoutubeEmbed;
