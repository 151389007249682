import React from 'react';
import HeadContent from './HeadContent';
import Footer from './Footer';
import TopNav from './TopNav';
import { LayoutProps } from '../types/layout/LayoutTypes';

const Layout: React.FC<LayoutProps> = (props) => {
  const { head, children } = props;
  return (
    <>
      <HeadContent {...head} />
      <div
        className="app flex flex-col min-h-screen bg-a-darker-gray"
        id="app-wrapper"
        data-testid="layoutContainer"
      >
        <TopNav />
        <main className="app-body flex-grow bg-a-darker-gray text-white" data-testid="bodyWrapper">
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
