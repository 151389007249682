import { GetServerSideProps } from 'next';
import { AppProps } from '../types/pages/index';

import Layout from '../layout/Layout';
import Homepage from '../components/homepage/Homepage';

import { useEffect} from 'react';
import errorRedirectHandler from '../helpers/errorRedirectHandler';
import { fetchHomepageServersideProps } from '../helpers/pages/homepage/homepage';
import { useDispatch } from 'react-redux';
import { setHomepageVisited, clearHomepageVisited } from '../state/actions/eventTrackingActions';

const App: React.FC<AppProps> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHomepageVisited());
    return () => {
      dispatch(clearHomepageVisited());
    };
  }, []);

  return (
    <Layout>
      <Homepage content={props.content} />
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    return fetchHomepageServersideProps();
  } catch (error) {
    errorRedirectHandler(error);
  }
};

export default App;
