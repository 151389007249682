import Slider from 'react-slick';
import { CarouselProps } from '../types/modules/CarouselTypes';

export const Carousel: React.FC<CarouselProps> = (props) => {
  return (
    <Slider
      {...props.carouselOptions}
      className="md:max-w-[700px] lg:max-w-[950px] xl:max-w-[1400px] 2xl:max-w-[1920px] 1900p:max-w-[1997px] 4k:max-w-[2400px] ultra-wide:max-w-[3400px]"
    >
      {props.carouselElements}
    </Slider>
  );
};
