import { FetchHookResponse } from '../types/hooks/hooksTypes';
import { HomepageFeaturedCollectionsData } from '../types/hooks/getHomepageFeaturedCollections';

import { useState, useEffect } from 'react';
import getFeaturedCollections from '../helpers/pages/homepage/getFeaturedCollections';

export default function getHomepageFeaturedCollections(): FetchHookResponse<HomepageFeaturedCollectionsData> {
  const [data, setData] = useState<HomepageFeaturedCollectionsData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const result: HomepageFeaturedCollectionsData = await getFeaturedCollections() as HomepageFeaturedCollectionsData;
        setData(result);
        setLoading(false);
      }
      catch (e) {
        setError(e);
        setLoading(false);
      }
    }

    getData();
  }, []);

  return { loading, data, error };
};
