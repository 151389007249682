import React, { useState } from 'react';
import { HomepageTestimonialProps } from '../../types/pages/homepage/HomepageTypes';
import { QuoteIcon } from '../../modules/Icons';
import PlayVideoModal from '../../components/shared/PlayVideoModal';

const HomepageTestimonials: React.FC<HomepageTestimonialProps> = (props) => {
  const { testimonial_text, customer_name, testimonial_image, customer_title, video_link } = props;

  return (
    <div className="text-white">
     {video_link &&  <PlayVideoModal image={testimonial_image.filename} video_link={video_link.url} />}
      <div className="px-6 md:px-12 py-8">
        <div className='ml-[-18px]'>
          <QuoteIcon className="absolute !fill-white !opacity-[20%]" />
        </div>
        <div className="text-left">
          <p className="italic text-[18px] leading-relaxed pt-6 w-10/12">{testimonial_text}</p>
          {customer_name && (
            <>
              <div className="flex text-sm py-6">
                <div className='flex flex-col'>
                  <span className="font-medium text-lg">{customer_name}</span>
                  <br />
                  <span className="opacity-60">{customer_title}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomepageTestimonials;
