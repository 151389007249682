import React from 'react';
import { Carousel } from '../../modules/Carousel';
import { RightArrowIcon, LeftArrowIcon, AssetIcon } from '../../modules/Icons';
import { CarouselArrowProps } from '../../types/pages/homepage/HomepageTypes';
import { CollectionSliderResponse } from '../../types/pages/homepage/HomepageTypes';
import { ENV } from '../../constants/environments';
import Image from 'next/image';

const CollectionSliderContainer: React.FC<CollectionSliderResponse> = (props) => {
  const carouselOptions = {
    arrows: false,
    rows: 1,
    variableWidth: true,
    infinite: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // Function to wrap the first two words in a span
  const renderText = (elements) => {
    const text = typeof elements === 'string' ? elements : String(elements);

    const words = text.split(' ');

    if (words.length > 2) {
      return (
        <>
          <span className="wrap-after">{words.slice(0, 2).join(' ')}</span>{' '}
          {words.slice(2).join(' ')}
        </>
      );
    } else {
      return text;
    }
  };

  const getTextStyle = (text) => {
    const wordCount = text.split(' ').length;
    return wordCount >= 3 ? 'text-[12px]' : 'text-[16px]';
  };

  const hrefGenerator = (slug, id) => {
    if (id === 1) {
      return `/collections/${slug}`;
    } else if (id === 2) {
      return `/practice-footage/${slug}`;
    } else {
      return `/3d/${slug}`;
    }
  };

  const collectionSlides: JSX.Element[] = props.collections.map((item, index) => {
    return (
      <div key={`collection-slide-${index}`}>
        <a href={hrefGenerator(item.slug, item.library.id)}>
          <div className="w-[240px] h-[135px] lg:w-[480px] lg:h-[270px] flex-shrink-0 rounded-[5px] overflow-hidden relative bg-cover mr-3">
            <Image
              className="absolute z-0 top-0 left-0 w-full h-full"
              src={ENV.uploadsUrl + item.media.image}
              loading="lazy"
              priority={false}
              fill={true}
              alt={item.name}
            />
            <div className="absolute left-0 bottom-0 right-0 top-[20%] bg-gradient-to-t from-black opacity-70"></div>

            <div className="absolute bottom-3 left-3 right-3 flex justify-between items-end">
              <h2
                className={`inter text-white ${getTextStyle(
                  item.name
                )} md:text-[16px] font-[200] tracking-wider`}
              >
                {item.name}
              </h2>

              {/* Collection Elements Count and Icon */}
              <div className="bg-white bg-opacity-[16%] rounded-full px-2">
                <div className="flex gap-1 items-center text-white text-[14px] font-[200] md:text-[16px]">
                  <AssetIcon />
                  <span>{item.elements}</span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  });

  return (
    <div className="homepage-collection-slider relative pb-8 md:pb-0">
      <Carousel carouselElements={collectionSlides} carouselOptions={carouselOptions} />
    </div>
  );
};

const SliderCarouselArrow: React.FC<CarouselArrowProps> = (props) => {
  if (!props.next)
    return (
      <div
        className={`hidden opacity-[30%] md:block absolute bottom-[-40px] md:bottom-auto md:top-[-80px] text-2xl md:right-0 text-white`}
        onClick={props.onClick}
      >
        <div className="relative md:right-[150px] cursor-pointer">
          <LeftArrowIcon />
        </div>
      </div>
    );
  else
    return (
      <div
        className={`hidden absolute bottom-[-40px] md:bottom-auto md:top-[-80px] text-2xl right-0 text-white`}
        onClick={props.onClick}
      >
        <div className="relative mr-8 md:mr-0 md:right-[75px] cursor-pointer">
          <RightArrowIcon />
        </div>
      </div>
    );
};

export default CollectionSliderContainer;
