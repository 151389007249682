import React from 'react';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { CtaProps } from '../../types/pages/homepage/CtaTypes';

export const wrapperClasses = 'flex justify-center items-center gap-5 flex-col py-16 md:py-24 relative mx-auto';
export const buttonClasses = '!text-white !w-[258px] !h-[56px]';

const Cta: React.FC<CtaProps> = ({
  backgroundImage,
  heading,
  subheading,
  buttonText,
  buttonHref,
  variant
}) => {
  const createMarkup = (html: string) => ({ __html: html });

  return (
    <section
      className={wrapperClasses}
      style={{
        backgroundImage: `url(${backgroundImage}), url(/img/home/options-bg.jpg)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
    >
      <div dangerouslySetInnerHTML={createMarkup(heading)} />
      <div dangerouslySetInnerHTML={createMarkup(subheading)} />
      <MainLinkButton
        href={buttonHref}
        variant={variant}
        className={buttonClasses}
      >
        {buttonText}
      </MainLinkButton>
    </section>
  );
};

export default Cta;
