import React, { useState } from 'react';
import { PlayIcon, PlayIconActive, PlayIconBlack } from '../../modules/Icons';
import YoutubeEmbed from './YoutubeEmbed';
import Modal from 'react-responsive-modal';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';

const PlayVideoModal = (props) => {
  const [open, setOpen] = useState(false);
  const [videoHovered, setVideoHovered] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const { image, video_link, containerClasses, type, content } = props;

  return (
    <>
      {type == 'text' && (
        <div
          className="h-[36px] flex justify-center mt-6 items-center align-center gap-2 text-a-blue text-[17px] uppercase eurostile font-bold cursor-pointer"
          onClick={() => setOpen(true)}
          onMouseOver={() => setVideoHovered(true)}
          onMouseOut={() => setVideoHovered(false)}
        >
          <PlayIcon className="w-[36px] inline" />
          {content}
        </div>
      )}

      {type == 'button' && (
        <MainLinkButton
          onClick={() => setOpen(true)}
          onMouseOver={() => setVideoHovered(true)}
          onMouseOut={() => setVideoHovered(false)}
          className="h-[56px] !py-4 px-4"
        >
          <PlayIconBlack className="w-[36px] inline md:mr-1" />
          {content}
        </MainLinkButton>
      )}

      {type != 'text' && type != 'button' && (
        <div className={`relative mt-[-80px] md:mt-[-100px] xl:mt-[-150px] ${containerClasses}`}>
          <img
            className="mx-auto w-[90%] rounded-[7px]"
            src={props.image}
            alt="ActionVFX testimonial preview image"
          />
          <div
            onClick={() => setOpen(true)}
            onMouseOver={() => setVideoHovered(true)}
            onMouseOut={() => setVideoHovered(false)}
            className="absolute w-full lg:h-full flex justify-center items-center top-0 cursor-pointer"
          >
            <PlayIcon className="w-[100px] " />
            <PlayIconActive
              className={`w-[100px] absolute opacity-0 ${
                videoHovered ? 'opacity-100 ease-linear transition-all duration-200' : ''
              }`}
            />
          </div>
        </div>
      )}
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        showCloseIcon={false}
        classNames={{ modal: '!p-0 !bg-transparent !m-0 w-[100%] lg:w-[800px] 2xl:w-[1400px]' }}
      >
        <YoutubeEmbed embedId={video_link} className="bg-none" />
      </Modal>
    </>
  );
};

export default PlayVideoModal;
