import React, { useState } from 'react';
import { ENV } from '../../constants/environments';
import VideoCover from 'react-video-cover';
import { AssetIcon } from '../../modules/Icons';
import { getCollectionPath } from '../../helpers/pages/collections/collectionsHelpers';

const CollectionGrid = (props) => {
  const { collections } = props;
  const [hoveredCollection, setHoveredCollection] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredCollection(id);
  };

  const handleMouseLeave = () => {
    setHoveredCollection(null);
  };

  // Function to wrap the first two words in a span
  const renderText = (elements) => {
    const text = typeof elements === 'string' ? elements : String(elements);

    const words = text.split(' ');

    if (words.length > 3) {
      return (
        <>
          <span className="wrap-after">{words.slice(0, 3).join(' ')}</span>{' '}
          {words.slice(3).join(' ')}
        </>
      );
    } else {
      return text;
    }
  };

  const getTextStyle = (text) => {
    const wordCount = text.split(' ').length;
    return wordCount >= 3 ? 'text-[14px]' : 'text-[16px]';
  };

  return (
    <div className="flex gap-4">
      <div>
        {collections?.slice(0, 1).map((collection, index) => (
          <div
            key={`collection-slide-${index}`}
            onMouseEnter={() => handleMouseEnter(collection.id)}
            // onMouseEnter={() => handleMouseEnter(collection.id)}
            onMouseLeave={handleMouseLeave}
          >
            {/* Content for large item */}
            <a
              href={getCollectionPath(collection.slug, collection.library)}
              className="w-full h-full block"
            >
              <div
                className="w-[470px] h-[264.38px] xl:w-[570px] xl:h-[320.63px] 2xl:w-[700px] 2xl:h-[393.75px] ultra-wide:w-[1300px] ultra-wide:h-[731.25px] rounded-[5px] relative bg-cover"
                // SUPER HACK TO GET THE CORRECT IMAGE TO SHOW UP
                style={{
                  backgroundImage: `url(${
                    ENV.uploadsUrl + collection.media.image.replace('medium_', '')
                  })`,
                  backgroundSize: 'center'
                }}
              >
                <div className="hover:border hover:border-[#4D5457] top-0 h-[100%] w-[100%] rounded-[5px] overflow-hidden">
                  {hoveredCollection === collection.id && (
                    <VideoCover
                      videoOptions={{
                        src: collection.media.mp4,
                        autoPlay: true,
                        loop: true,
                        muted: true
                      }}
                    />
                  )}
                </div>
                <div className="absolute left-0 bottom-0 right-0 top-[20%] bg-gradient-to-t from-black opacity-70"></div>
                <div className="absolute left-4 bottom-1 right-0 flex justify-between w-fill mb-3">
                  <div className="gap-2">
                    <h2 className="2xl:!text-[20px] leading-24 font-[400] inter">
                      {collection.name}
                    </h2>
                  </div>

                  <div className="absolute items-center right-2 px-1.5 py-.5 bg-white bg-opacity-[16%] rounded-full w-fit mr-2">
                    <div className="flex gap-1 text-[14px] font-[500] leading-24">
                      <AssetIcon />
                      {collection.elements}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 grid-rows-2 gap-y-3 gap-x-0">
        {collections?.slice(1, 5).map((collection, index) => (
          <div
            key={`collection-slide-${index}`}
            onMouseEnter={() => handleMouseEnter(collection.id)}
            onMouseLeave={handleMouseLeave}
          >
            <a href={getCollectionPath(collection.slug, collection.library)}>
              <div
                className="w-[218px] h-[122.63px] xl:w-[275px] xl:h-[154.69px] 2xl:w-[340px] 2xl:h-[191.25px] ultra-wide:w-[630px] ultra-wide:h-[354.38px] flex-shrink-0 rounded-[5px] overflow-hidden relative bg-cover mr-3"
                style={{ backgroundImage: `url(${ENV.uploadsUrl + collection.media.image})` }}
              >
                <div className="hover:border hover:border-[#4D5457] top-0 h-[100%] w-[100%] rounded-[5px] overflow-hidden">
                  {hoveredCollection === collection.id && (
                    <VideoCover
                      videoOptions={{
                        src: collection.media.mp4,
                        autoPlay: true,
                        loop: true,
                        muted: true
                      }}
                    />
                  )}
                </div>
                <div className="absolute left-0 bottom-0 right-0 top-[20%] bg-gradient-to-t from-black opacity-70"></div>
                <div className="absolute left-4 bottom-0 right-0 flex items-center justify-between w-fill mb-3">
                  <div className="gap-2">
                    <div
                      className={`${getTextStyle(
                        collection.name
                      )} text-[10px] ultra-wide:text-[16px] font-[400] leading-24 inter`}
                    >
                      {renderText(collection.name)}
                    </div>
                  </div>

                  <div className="absolute right-2 items-center px-1.5 2xl:py-.5 bg-white bg-opacity-[16%] rounded-full w-fit mr-.5 xl:mr-2">
                    <div className="flex gap-1 text-[14px] leading-24 font-[500]">
                      <AssetIcon />
                      {collection.elements}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CollectionGrid;
